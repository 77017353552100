<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <q-card flat bordered class="row">
        <q-card-section class="col q-gutter-md">
          <base-input-select v-bind="formInputProps('type')" color="per" dense
            v-model="formData.type" ref="type" />
        </q-card-section>

        <q-card-section class="col">
          <base-input-date v-bind="formInputProps('date')" color="per"
            v-model="formData.date" ref="date" />
        </q-card-section>
      </q-card>

      <div v-if="formData.type" class="row q-col-gutter-md">
        <div class="col col-6">
          <h6>{{sousCibleLabel}}</h6>
          <div v-if="!$route.meta.isDetail && formInputProps('virtual_entity_name')">
            <div class="row q-col-gutter-md">
              <div class="col col-12">
                <base-input-text v-bind="formInputProps('virtual_entity_name')" color="per"
                  v-model="formData.virtual_entity_name" ref="admin_note" />
              </div>
            </div>
          </div>
          <div class="row q-col-gutter-md" v-else>
            <div class="col col-12" v-for="related_obj in relatedObjects" :key="related_obj.id">
              <base-card :actions="isAdmin ? related_obj.actions : []" @menuClick="onMenuClick">
                <q-avatar slot='avatar' size="3rem" color="reseau" text-color="white" :icon="related_obj.icon" />

                <div slot='default'>
                  <q-item-label class="q-mb-md"><strong>{{related_obj.label}}</strong></q-item-label>
                </div>
              </base-card>
            </div>
            <div class="col col-12" v-if="!$route.meta.isDetail">
              <search-box ref="searchBox" v-if="displayObjectSelect()" class="col col-5"
                :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
                v-model="linkedObjModel" :options="linkedObjModelOptions" />
            </div>
          </div>
        </div>
        <div class="col col-6" v-if="formInputProps('virtual_wallet_name') && relatedObjects.length > 0 && !$route.meta.isDetail">
          <h6>{{$t('sousCibles.fields.virtual_wallet_name.label')}}</h6>
          <div class="row q-col-gutter-md">
              <div class="col col-12">
                <base-input-text v-bind="formInputProps('virtual_wallet_name')" color="per"
                  v-model="formData.virtual_wallet_name" ref="admin_note" />
              </div>
            </div>
        </div>
        <div class="col col-6"  v-if="formInputProps('virtual_agent_name') && relatedObjects.length > 0 && !$route.meta.isDetail">
          <h6>{{$t('sousCibles.fields.virtual_agent_name.label')}}</h6>
          <div class="row q-col-gutter-md">
              <div class="col col-12">
                <base-input-text v-bind="formInputProps('virtual_agent_name')" color="per"
                  v-model="formData.virtual_agent_name" ref="admin_note" />
              </div>
            </div>
        </div>
      </div>

      <div class="q-my-md" v-if="cible">
        <h6>{{$t('tasks.tasks')}}</h6>
        <q-btn icon="add_circle" :label="$t('tasks.add_task')" class="bg-white q-pa-lg" color="per full-width" outline
          :to="{ name: 'task-create', params: { model_type: 'cible', model_object: cible } }" />

        <TasksTimeline :tasks="cible.taches" color="per" />
      </div>
    </div>

    <div class="col col-2">
      <q-list class="full-width">
        <q-item class="q-pa-none" v-if="formInputProps('date_validation')">
          <base-input-date v-bind="formInputProps('date_validation')" color="per"
            v-model="formData.date_validation" ref="date_validation" />
        </q-item>
        <q-item class="q-pa-none" v-if="$route.params.id && formInputProps('date_realisation')">
          <base-input-date v-bind="formInputProps('date_realisation')" color="per"
            v-model="formData.date_realisation" ref="date_realisation" />
        </q-item>
        <q-item class="q-pa-none" v-if="formInputProps('statut')">
          <base-input-select v-bind="formInputProps('statut')" color="per"
            v-model="formData.statut" ref="statut" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('qualification')">
          <base-input-select v-bind="formInputProps('qualification')" color="per"
            v-model="formData.qualification" ref="qualification" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('region_id')">
          <base-input-select v-bind="formInputProps('region_id')" color="per"
            v-model="formData.region_id" ref="region_id" />
        </q-item>
        <q-item class="column items-start q-pa-none" v-if="formInputProps('switch_comptage') || formInputProps('switch_meteo')">
          {{$t('cibles.integrer_stats_PER')}}
          <base-input-boolean
            v-model="formData.switch_comptage"
            v-bind="formInputProps('switch_comptage')"
            v-if="formInputProps('switch_comptage')"
            color="per"
            class="row"
          />

          <base-input-boolean
            v-model="formData.switch_meteo"
            v-bind="formInputProps('switch_meteo')"
            v-if="formInputProps('switch_meteo')"
            color="per"
          />
        </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn color="per full-width" size="sm" unelevated type="submit" :disabled="!hasChanged || !isAdmin">{{$t('form.submit')}}</q-btn>
        <q-btn color="per full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
        <q-btn color="negative full-width" size="sm" unelevated icon-right="delete" :disabled="!isAdmin"
          v-if="cible" @click.native="destroy">{{$t('form.delete')}}</q-btn>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import { TasksTimeline } from '../../components/tasks'

export default {
  name: 'SousCibleDetails',
  mixins: [BaseForm],
  components: { TasksTimeline },
  props: {
    'form_model': { type: String, default: 'sousCibles' },
    'action': { type: String, default: 'sousCibles/saveSousCible' },
    'groupe_cible': Object
  },

  data () {
    return {
      relatedObjects: [],
      minNbLinkObjects: null,
      maxNbLinkObjects: null,
      linkedObjModel: null
    }
  },

  computed: {
    sousCibleLabel () {
      return this.isEntityCreationSelected() ? this.$t('sousCibles.fields.virtual_entity_name.label') : this.$t('sousCibles.fields.model.label')
    },
    linkedObjMenu () {
      return !this.isCurrentUserDestinataire ? [
        { label: this.$t('tasks.remove_linked_object'), eventName: 'remove' }
      ] : []
    },

    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      cible: 'sousCibles/getCurrentSousCible'
    }),

    linkedObjModelOptions () {
      const model = this.formFields.find(field => field.id === 'model_type')
      return model.options.map(({ label, value }) => ({ label: value, value: label.toLowerCase() }))
    }
  },

  watch: {
    cible: {
      handler (newVal, oldVal) {
        if (!newVal) return

        this.formData = {
          ...this.formData,
          id: newVal['id'],
          ...this.formFields.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === newVal[id]) : newVal[id]
            }), {})
        }
        this.formData['groupe_cible'] = newVal['groupe_cible']

        const relatedReseauObjects = []
          .concat(newVal.agents)
          .concat(newVal.wallets)
          .concat(newVal.entities)

        this.relatedObjects = relatedReseauObjects.map(obj => {
          let label, icon, model_type, model_id = obj.id
          let actions = [
            { label: this.$t('tasks.remove_linked_object'), payload: { name: 'task-detach', params: { id: model_id } } }
          ]

          if (obj['NOMITV']) {
            model_type = 'agent'
            label = obj['TITULAIRE']
            icon = 'person'
          } else if (obj['NOMPTF']) {
            model_type = 'wallet'
            label = obj['NOMPTF']
            icon = 'account_balance_wallet'
          } else if (obj['CODEICX']) {
            model_type = 'entity'
            label = obj['label']
            icon = 'account_balance_wallet'
          }

          return { model_type, model_id, label, icon, actions }
        })

        // console.log(this.relatedObjects)
        this.formData.related_objects = this.relatedObjects
      },
      immediate: true
    },

    linkedObjModel: {
      handler (newVal, oldVal) {
        const { type, value } = newVal
        if (value) {
          const { label, value: id } = value
          let icon, actions = [
            { label: this.$t('tasks.remove_linked_object'), payload: { name: 'task-detach', params: { id } } }
          ]

          if (type === 'agent') {
            icon = 'person'
          } else if (type === 'wallet') {
            icon = 'account_balance_wallet'
          } else if (type === 'entity') {
            icon = 'account_balance_wallet'
          }

          this.relatedObjects.push({ model_type: type, model_id: id, label, icon, actions })
          this.$refs['searchBox'].reset()
        }
      },
      deep: true
    },

    relatedObjects (newVal, oldVal) {
      this.formData.related_objects = newVal.map(({ model_type, model_id }) => ({
        model_type: model_type.split('.').pop(), model_id
      }))
    },

    'formData.type': {
      handler (newVal, oldVal) {
        if (!newVal) return
        this.$store.dispatch('sousCibles/fetchFormFields', { droits_title: newVal.value })
          .then(fields => {
            const { mini, maxi } = fields.find(field => field.id === 'model_type')
            this.minNbLinkObjects = mini
            this.maxNbLinkObjects = maxi
            if (this.cible && newVal !== this.cible.type) {
              this.formData.related_objects = []
            }
          })
      },
      immediate: true
    }
  },

  mounted () {
    if (!this.groupe_cible) {
      if (!this.$route.params.id) {
        this.$router.go(-1)
      }
    } else {
      this.formData = {
        ...this.formData,
        groupe_cible: this.groupe_cible.id,
        date: this.$formatDate(this.groupe_cible.date, 'YYYY-MM-DD')
      }
    }
  },

  methods: {
    isEntityCreationSelected () {
      return this.formData.type.value === 'CREATION_ENTITE'
    },
    isWalletCreationSelected () {
      return this.formData.type.value === 'CREATION_PORTEFEUILLE'
    },
    isAgentCreationSelected () {
      return this.formData.type.value === 'NOMINATION'
    },
    displayObjectSelect () {
      if (this.maxNbLinkObjects) {
        return this.relatedObjects.length < this.maxNbLinkObjects
      }
      return true
    },

    onSave () {
      if (this.$route.params.id) {
        this.$store.dispatch('sousCibles/getSousCible', this.$route.params.id)
      } else {
        this.$router.go(-1)
      }
    },

    destroy () {
      this.$confirm(this.$t('sousCibles.delete_cible_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('sousCibles/deleteSousCible', this.$route.params.id).then(() => {
          this.notifySuccess('sousCibles.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    },

    onMenuClick ({ name, params }) {
      this.formData.related_objects = this.formData.related_objects.filter(obj => obj.model_id !== params.id)
      this.relatedObjects = this.relatedObjects.filter(obj => obj.model_id !== params.id)
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
<!--  -->
